import {useEffect, useState} from "react";

export default function usePasswordValidator(password, confirmPassword) {
    const [atLeast7CharLong, setAtLeast7CharLong] = useState(false)
    const [uppercase, setUpperCase] = useState(false)
    const [lowercase, setLowercase] = useState(false)
    const [numbers, setNumbers] = useState(false)
    const [match, setMatch] = useState(false)
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        setAtLeast7CharLong(password?.length > 7)
        setUpperCase(/[A-Z]{1}/.test(password))
        setLowercase(/[a-z]{1}/.test(password) && password?.length > 0)
        setNumbers(/[0-9]{1}/.test(password))
        setMatch(password === confirmPassword && password?.length > 0)
    }, [password, confirmPassword])
    
    useEffect(() => {
        setIsValid(atLeast7CharLong && uppercase && lowercase && numbers && match)
    }, [atLeast7CharLong, uppercase, lowercase, numbers, match])

    return {isValid, atLeast7CharLong, uppercase, lowercase, numbers, match}
}