import React, { Component } from "react";
import AjaxService from "../../services/AjaxService";

export default class AssessmentResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      results: null,
      summary: null,
      doughnutConfig: null,
      selectedSelfAssessment: null,
      selectedAssessment: null,
    };
  }

  setDoughnutConfig = () => {
    const overallPerformanceOptions = {
      responsive: false,
      cutout: "50%",
      ratio: 2,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          display: false,
          grid: {
            display: false,
          },
        },
      },
    };

    const overallPerformanceData = {
      labels: [
        "Beginning CONCERNED",
        "Highly CONCERNED",
        "Beggining PRO-ACTIVIST",
        "Highly PRO-ACTIVIST",
        "Beginning CIRCULAR",
        "Highly CIRCULAR",
      ],
      datasets: [
        {
          data: [1, 1, 1, 1, 1, 1],
          backgroundColor: [
            "rgb(246, 153, 57)",
            "rgb(255, 247, 106)",
            "rgb(207, 243, 85)",
            "rgb(132, 207, 30)",
            "rgb(28, 206, 144)",
            "rgb(29, 207, 193)",
          ],
        },
        {
          data: [
            this.state.results.overallCircularTourismPerformance.percentage,
            100 -
              this.state.results.overallCircularTourismPerformance.percentage,
          ],
          backgroundColor: ["rgb(128, 128, 128)", "rgb(224, 224, 224)"],
        },
      ],
    };

    const overallPerformanceCtx = document
      .getElementById("overallPerformance")
      .getContext("2d");

    const overallPerformanceChart = new Chart(overallPerformanceCtx, {
      type: "doughnut",
      data: overallPerformanceData,
      options: overallPerformanceOptions,
    });

    this.setState({ ...this.state, doughnutConfig: overallPerformanceChart });
  };

  getSelfAssessmentResult = () => {
    this.setState({ ...this.state, isLoading: true }, () => {
      AjaxService.get(
        `/api/self-assessment/self-assessments/${this.state.selfAssessmentId}/result`,
        (data) => {
          this.setState({
            ...this.state,
            results: data,
          });
          this.setDoughnutConfig();
        },
        (data) => {
          console.error(data);
        }
      );
    });
  };
  getSelfAssessmentSummary = () => {
    this.setState({ ...this.state, isLoading: true }, () => {
      AjaxService.get(
        `/api/self-assessment/self-assessments/${this.state.selfAssessmentId}/summary`,
        (data) => {
          this.setState({
            ...this.state,
            summary: data.summary,
            selectedSelfAssessment: data.selfAssessment,
            selectedAssessment: data.questionnaireVersion,
          });
        },
        (data) => {
          console.error(data);
        }
      );
    });
  };

  getSelfAssessmentResults = () => {
    this.getSelfAssessmentResult();
    this.getSelfAssessmentSummary();
  };

  componentDidMount() {
    this.getSelfAssessmentResults();
  }

  stripHtml(html)
  {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  buildCapitalSummaries = () => {

    if(this.state.summary){
      let capitalIndex = 0
      return this.state.summary.map((c) => {
        capitalIndex = capitalIndex > 4 ? 1 : capitalIndex + 1;
        return this.buildCapitalSummary(c, capitalIndex);
      })
    }
    
    return (
        <div className="alert alert-info" role="alert">
          <i className="fa fa-info-circle" aria-hidden="true"></i> No
          summary information.
        </div>
    )
  }
  
  buildCapitalSummary = (capital, capitalIndex) => {
    return (
      <div className={`capital ${capital.capital.toLowerCase().replaceAll(" ", "-")}-capital random-${capitalIndex}`}>
        <h5 className="content-cards-title">
          {capital.capital.toUpperCase()} CAPITAL
        </h5>
        {capital.answers.map((a) => {
          return (
            <div
              className="answer-item"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={this.stripHtml(a.description)}
            >
              <div className="fw-bold pull-right">{a.class.toUpperCase()}</div>
              <div className="d-flex">
                <p className="fw-bold question-title">
                  {a.letter?.toUpperCase() || "-"}
                </p>
                <div className="progress">
                  <div
                    style={{ width: `${a.value}%` }}
                    className="progress-bar progress-bar-striped fw-bold assessment-result-bar"
                    role="progressbar"
                    aria-valuenow={a.value}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {`${Math.round(a.value)}%`}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  onView = () => {
    const url = `/self-assessment-page?qId=${this.state.selectedAssessment.questionnaireId}&vN=${this.state.selectedAssessment.versionNumber}&saId=${this.state.selectedSelfAssessment.id}`;
    window.location.href = url;
  };

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="content-cards">
        {this.state.summary && (
          <>
            <h3 className="content-cards-title">
              Result for {this.state.selectedSelfAssessment?.title}
            </h3>
            <p>{this.state.selectedAssessment?.title}</p>
            <div className="buttons">
              <button className="btn btn-success" onClick={this.onView}>
                <i className="fa fa-search" aria-hidden="true"></i>View
                questionnaire
              </button>
              <button onClick={this.print} className="btn btn-success">
                <i className="fa fa-print"></i>Print recap
              </button>
            </div>
          </>
        )}
        <h4 className="content-cards-title">Statistics</h4>
        {this.state.results && (
          <>
            <div id="overall-section">
              <div className="doughnut-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 600" className="position-absolute overlay-svg">
                  <g id="Livello_1" data-name="Livello 1">
                      <text class="cls-7" transform="translate(326.04 121.72) rotate(11.99)">B</text>
                      <text class="cls-7" transform="translate(336.75 124) rotate(15.41)">e</text>
                      <text class="cls-7" transform="matrix(0.95, 0.32, -0.32, 0.95, 345.91, 126.51)">g</text>
                      <text class="cls-7" transform="translate(355.16 129.68) rotate(21.04)">i</text>
                      <text class="cls-7" transform="translate(359.46 131.27) rotate(23.43)">n</text>
                      <text class="cls-7" transform="matrix(0.89, 0.45, -0.45, 0.89, 368.26, 135.07)">n</text>
                      <text class="cls-7" transform="translate(376.89 139.45) rotate(29.02)">i</text>
                      <text class="cls-7" transform="matrix(0.85, 0.52, -0.52, 0.85, 380.94, 141.63)">n</text>
                      <text class="cls-7" transform="matrix(0.82, 0.57, -0.57, 0.82, 389.12, 146.61)">g</text>
                      <text class="cls-7" transform="translate(397.16 152.23) rotate(36.87)"></text>
                      <text class="cls-7" transform="matrix(0.69, 0.72, -0.72, 0.69, 416.46, 168.54)">C</text>
                      <text class="cls-7" transform="matrix(0.64, 0.77, -0.77, 0.64, 423.46, 175.8)">O</text>
                      <text class="cls-7" transform="translate(431.71 185.67) rotate(54.32)">N</text>
                      <text class="cls-7" transform="translate(438.9 195.71) rotate(58.13)">C</text>
                      <text class="cls-7" transform="translate(444.47 204.66) rotate(61.53)">E</text>
                      <text class="cls-7" transform="translate(449.19 213.29) rotate(64.99)">R</text>
                      <text class="cls-7" transform="translate(453.8 223.08) rotate(68.87)">N</text>
                      <text class="cls-7" transform="matrix(0.3, 0.95, -0.95, 0.3, 458.23, 234.61)">E</text>
                      <text class="cls-7" transform="matrix(0.24, 0.97, -0.97, 0.24, 461.21, 243.95)">D</text>
                      <text class="cls-7" transform="translate(464.02 255.78) rotate(78.91)"></text>
                      <text class="cls-7" transform="translate(464.54 258.41) rotate(79.8)"></text>
                      <text class="cls-7" transform="translate(467.74 292.5) rotate(92.48)">H</text>
                      <text class="cls-7" transform="matrix(-0.09, 1, -1, -0.09, 467.14, 304.57)">i</text>
                      <text class="cls-7" transform="matrix(-0.13, 0.99, -0.99, -0.13, 466.78, 309.15)">g</text>
                      <text class="cls-7" transform="translate(465.51 318.74) rotate(100.81)">h</text>
                      <text class="cls-7" transform="translate(463.66 328.23) rotate(103.27)">l</text>
                      <text class="cls-7" transform="translate(462.59 332.99) rotate(105.56)">y</text>
                      <text class="cls-7" transform="translate(129.99 338.05) rotate(-103.84)">B</text>
                      <text class="cls-7" transform="translate(127.38 327.41) rotate(-100.42)">e</text>
                      <text class="cls-7" transform="matrix(-0.13, -0.99, 0.99, -0.13, 125.65, 318.08)">g</text>
                      <text class="cls-7" transform="translate(124.47 308.37) rotate(-94.85)">i</text>
                      <text class="cls-7" transform="translate(124.02 303.78) rotate(-92.49)">n</text>
                      <text class="cls-7" transform="matrix(0.01, -1, 1, 0.01, 123.59, 294.22)">n</text>
                      <text class="cls-7" transform="matrix(0.05, -1, 1, 0.05, 123.74, 284.51)">i</text>
                      <text class="cls-7" transform="matrix(0.09, -1, 1, 0.09, 123.92, 279.92)">n</text>
                      <text class="cls-7" transform="translate(124.8 270.39) rotate(-81.48)">g</text>
                      <text class="cls-7" transform="translate(126.3 260.72) rotate(-79.22)"></text>
                      <text class="cls-7" transform="translate(132.48 236.22) rotate(-69.58)">C</text>
                      <text class="cls-7" transform="matrix(0.39, -0.92, 0.92, 0.39, 136.25, 226.26)">I</text>
                      <text class="cls-7" transform="translate(138.14 221.6) rotate(-64.27)">R</text>
                      <text class="cls-7" transform="translate(142.71 212.09) rotate(-60.8)">C</text>
                      <text class="cls-7" transform="matrix(0.54, -0.84, 0.84, 0.54, 147.81, 202.88)">U</text>
                      <text class="cls-7" transform="matrix(0.59, -0.81, 0.81, 0.59, 154.11, 193.18)">L</text>
                      <text class="cls-7" transform="matrix(0.64, -0.77, 0.77, 0.64, 159.77, 185.42)">A</text>
                      <text class="cls-7" transform="matrix(0.69, -0.72, 0.72, 0.69, 167.31, 176.41)">R</text>
                      <text class="cls-7" transform="matrix(0.72, -0.69, 0.69, 0.72, 174.95, 168.51)"></text>
                      <text class="cls-7" transform="matrix(0.82, -0.57, 0.57, 0.82, 192.03, 153.65)">H</text>
                      <text class="cls-7" transform="matrix(0.85, -0.53, 0.53, 0.85, 202, 146.78)">i</text>
                      <text class="cls-7" transform="matrix(0.87, -0.5, 0.5, 0.87, 205.84, 144.28)">g</text>
                      <text class="cls-7" transform="translate(214.23 139.47) rotate(-26.58)">h</text>
                      <text class="cls-7" transform="translate(222.9 135.18) rotate(-24.12)">l</text>
                      <text class="cls-7" transform="translate(227.33 133.14) rotate(-21.82)">y</text>
                      <text class="cls-7" transform="matrix(-0.59, 0.8, -0.8, -0.59, 437.53, 388.61)">B</text>
                      <text class="cls-7" transform="translate(431.02 397.43) rotate(129.81)">e</text>
                      <text class="cls-7" transform="translate(424.94 404.74) rotate(132.99)">g</text>
                      <text class="cls-7" transform="matrix(-0.71, 0.7, -0.7, -0.71, 418.25, 411.86)">i</text>
                      <text class="cls-7" transform="matrix(-0.74, 0.67, -0.67, -0.74, 415.03, 415.12)">n</text>
                      <text class="cls-7" transform="translate(407.93 421.58) rotate(140.92)">n</text>
                      <text class="cls-7" transform="translate(400.38 427.66) rotate(143.29)">i</text>
                      <text class="cls-7" transform="translate(396.73 430.45) rotate(145.66)">n</text>
                      <text class="cls-7" transform="matrix(-0.86, 0.52, -0.52, -0.86, 388.83, 435.86)">g</text>
                      <text class="cls-7" transform="translate(380.44 440.87) rotate(151.12)"></text>
                      <text class="cls-7" transform="translate(357.61 451.76) rotate(160.75)">P</text>
                      <text class="cls-7" transform="translate(347.77 455.22) rotate(164.32)">R</text>
                      <text class="cls-7" transform="matrix(-0.98, 0.2, -0.2, -0.98, 337.65, 458.13)">O</text>
                      <text class="cls-7" transform="matrix(-0.99, 0.15, -0.15, -0.99, 324.58, 460.75)">-</text>
                      <text class="cls-7" transform="matrix(-0.99, 0.1, -0.1, -0.99, 319.25, 461.65)">A</text>
                      <text class="cls-7" transform="translate(307.97 462.8) rotate(177.77)">C</text>
                      <text class="cls-7" transform="translate(297.06 463.22) rotate(-178.76)">T</text>
                      <text class="cls-7" transform="matrix(-1, -0.06, 0.06, -1, 286.97, 462.95)">I</text>
                      <text class="cls-7" transform="matrix(-0.99, -0.11, 0.11, -0.99, 281.97, 462.72)">V</text>
                      <text class="cls-7" transform="translate(270.17 461.3) rotate(-170.68)">I</text>
                      <text class="cls-7" transform="translate(265.19 460.54) rotate(-168.25)">S</text>
                      <text class="cls-7" transform="translate(256.23 458.7) rotate(-165.09)">T</text>
                      <text class="cls-7" transform="translate(246.48 456.05) rotate(-162.77)"></text>
                      <text class="cls-7" transform="translate(223.4 447.33) rotate(-154.49)"></text>
                      <text class="cls-7" transform="translate(193.87 430.01) rotate(-141.66)">H</text>
                      <text class="cls-7" transform="matrix(-0.75, -0.66, 0.66, -0.75, 184.44, 422.46)">i</text>
                      <text class="cls-7" transform="matrix(-0.73, -0.69, 0.69, -0.73, 180.95, 419.49)">g</text>
                      <text class="cls-7" transform="matrix(-0.69, -0.73, 0.73, -0.69, 173.92, 412.83)">h</text>
                      <text class="cls-7" transform="translate(167.31 405.77) rotate(-130.9)">l</text>
                      <text class="cls-7" transform="translate(164.07 402.11) rotate(-128.62)">y</text>
                  </g>
              </svg>
                <div className="doughnut-center position-absolute fw-bold">
                  <span className="text-center center-title">
                    CIRCULAR TOURISM PERFORMANCE
                  </span>
                  <span
                    id="doughnutPercentageTxt"
                    className="text-center fw-bold text-danger"
                  >
                    {
                      this.state.results?.overallCircularTourismPerformance
                        .percentage
                    }
                    %
                  </span>
                </div>
                <p className="position-absolute fw-bold pct pct-17">17%</p>
                <p className="position-absolute fw-bold pct-lg pct-33">33%</p>
                <p className="position-absolute fw-bold pct pct-50">50%</p>
                <p className="position-absolute fw-bold pct-lg pct-67">67%</p>
                <p className="position-absolute fw-bold pct pct-83">83%</p>
                <p className="position-absolute fw-bold pct-lg pct-0">0%</p>
                <p className="position-absolute fw-bold pct-lg pct-100">100%</p>

                <canvas id="overallPerformance" height="450" width="450" />
              </div>
              <div className="performance-description">
                <h5>Circular Tourism Performance</h5>
                <p>
                  <strong>
                    Circular Tourism Performance for the current Tourism
                    Destination.
                  </strong>{" "}
                  This performance also represents the Integration level of CE
                  principles achieved by this last.
                </p>
              </div>
            </div>
            <div className="description">
              <span className="fw-bold">
                {this.state.results?.overallCircularTourismPerformance.title}
              </span>
              <div
                className="text-primary"
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.results?.overallCircularTourismPerformance
                      .description,
                }}
              ></div>
            </div>
          </>
        )}
        <>
          <h4 className="content-cards-title">Capitals</h4>
          { this.buildCapitalSummaries() }
        </>
        {this.state.results && (
          <>
            <h4 className="content-cards-title">
              Overall Collaboration Performance
            </h4>
            <div className="collaboration-performance-container">
              <div className="column-flex fw-bold percentage-labels">
                <span className="percentage-label" style={{ height: "13%" }}>
                  100%
                </span>
                <span className="percentage-label" style={{ height: "37%" }}>
                  85%
                </span>
                <span className="percentage-label" style={{ height: "50%" }}>
                  50%
                </span>
                <span className="percentage-label">0%</span>
              </div>
              <div id="collaboration-performanced-scale">
                <div
                  id="collaboration-performanced-value"
                  className="position-absolute text-center fw-bold"
                  style={{
                    bottom: `calc(${this.state.results?.overallCollaborationPerformance.percentage}% - 2.5px)`,
                  }}
                >
                  You are here
                </div>
              </div>
              <div
                className="column-flex collaboration-performance-labels"
                style={{ width: 100 }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ height: "30%" }}
                >
                  <span className="text-center collaboration-performanced-label">
                    Structured collaboration
                  </span>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ height: "35%" }}
                >
                  <span className="text-center collaboration-performanced-label">
                    Intermediate collaboration
                  </span>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ height: "30%" }}
                >
                  <span className="text-center collaboration-performanced-label">
                    Basic collaboration
                  </span>
                </div>
              </div>
              <p className="performance-description">
                <span className="fw-bold">
                  {this.state.results?.overallCollaborationPerformance.title}
                </span>
                <span
                  className="text-primary"
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.results?.overallCollaborationPerformance
                        .description,
                  }}
                ></span>
              </p>
            </div>
          </>
        )}
        <h4 className="content-cards-title">
          Best practices to improve your performance
        </h4>
        {
          this.state.selectedAssessment && (
              <div className="collaboration-performance-container">
                <p>
                  To find out how you can improve your performance, download 
                  the <a href={`/api/self-assessment/questionnaire-version/${this.state.selectedAssessment.questionnaireId}/${this.state.selectedAssessment.versionNumber}/how-to-improve`} target="_blank">following
                    dataset in excel format</a>! You will find good practices collected from all over the world
                  and linked to the questions you have just answered that can offer you useful ideas
                  to implement new circularity actions in your tourist destination.
                </p>
              </div>
          )
        }
        
      </div>
    );
  }
}
