import React, { Component } from "react";
import AvailableAssessments from "../components/available-assessments";
import SelfAssessments from "../components/self-assessments";
import AssessmentResult from "../components/assessment-result";
import AssessmentList from "../components/assessment-list";

export default class SurveysList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
    };
  }

  onSelfAssClicked = (selfAssessment) => {
    window.location.href = `/surveys?qId=${selfAssessment.questionnaireId}&saId=${selfAssessment.id}`
  };

  render() {
    return (
      <div className="surveylist">
        <div className="row">
          {(this.state.questionnaireId || this.state.selfAssessmentId) && 
            <div className="col col-md-4">
              <AvailableAssessments
                questionnaireId={this.state.questionnaireId}
                surveysPageUrl={this.state.surveysPageUrl}
              />
            </div>
          }
          <div className={`col col-md-8 ${ !(this.state.questionnaireId || this.state.selfAssessmentId) ? 'col-md-push-2' : null}`}>
            {this.state.questionnaireId ? (
              <>
                {this.state.selfAssessmentId ? (
                  <AssessmentResult
                    selfAssessmentId={this.state.selfAssessmentId}
                  />
                ) : (
                  <SelfAssessments
                    questionnaireId={this.state.questionnaireId}
                    isAdmin={this.state.isAdmin}
                    selfAssClicked={this.onSelfAssClicked}
                  />
                )}
              </>
            ) : (
                <AssessmentList />
            )}
            
          </div>
        </div>
      </div>
    );
  }
}
