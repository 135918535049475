import React from 'react'
import Input from "./input";
import IdService from "../services/IdService";
import FileSizeCalculator from "../services/FileSize";


class MultipleAttachments extends React.Component{
    initialState = undefined
    
    constructor(props) {
        super(props);
        
        this.state = {
            file: null,
            fileId: IdService.makeId(5),
            title: "",
            credits: "",
            language: "",
            isModalOpen: false,
        }
        this.initialState = {...this.state};
    }

    

    handleFileAdd = (event) => {
        let newFile = event.target.files;
        if (newFile) {
            this.setState({file: newFile[0]})
        }
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value})
    }
    
    handleCreditsChange = (event) => {
        this.setState({credits: event.target.value})
    }

    handleLanguageChange = (event) => {
        this.setState({language: event.target.value})
    }

    handleAddFile = () => {
        const resultModel = {
            credits: this.state.credits,
            title: this.state.title,
            language: this.state.language,
            file: this.state.file
        };

        if (resultModel.file) {
            this.props.addAttachment(resultModel);
            
            this.handleCancel();
        }
    };
    
    handleModalOpen = (value) => {
        this.setState({isModalOpen: value})
        if (value === true) {
            $("body").addClass("modal-open")
        } else if (!value) {
            $("body").removeClass("modal-open")
        }
    }

    handleCancel = () => {
        this.setState({...this.initialState})
        this.setState({fileId: IdService.makeId(5)})
        this.handleModalOpen(false);
    }

    getClassesName = () => {
        let classes = "multiple-files-modal ";
        classes += this.state.isModalOpen ? "is-modal-open" : "is-modal-closed";
        return classes;
    };

    getAttachments = () => {
        if (!this.props.attachments) return undefined;

        let showCredits = (att) => {
            if(!att.credits) return undefined;
            return (
                <div>
                    Credits: {att.credits}
                </div>
            )
        }
        
        let showLanguage = (att) => {
            if(!att.language) return undefined;
            return(
                <div>
                    Language: {att.language}
                </div>
            )
        }
        
        let index = 0;
        return this.props.attachments.map(att => {
                index++;
                return (
                    <div key={index} className="multiple-attachment-file">
                        <div className="attachment-remove-button">
                            <button type="button" className="attachment-remove-button" onClick={() => this.props.removeAttachment(index)}>
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="attachment-title">
                            {att.title}
                        </div>
                        <div className="attachment-file-name">
                            {att.file.name}
                            <span className="attachment-file-size">
                                    {FileSizeCalculator.calculate(att.file.size)}
                                </span>
                        </div>
                        <div className="attachment-language">
                            {showCredits(att)}
                            {showLanguage(att)}
                        </div>
                    </div>
                )
            }
        )
    }
    
    canSubmitNewAttachment = () => {
        return this.state.title && this.state.file;
    }
    
    render() {
        return (
            <div className="multiple-attachment-container">
                <div className="multiple-attachment-button-container">
                    <button type="button" className="multiple-attachment-button" onClick={() => { this.handleModalOpen(true) } }>
                        Add files
                    </button>
                </div>
                <div className="multiple-attachment-files">
                    {this.getAttachments()}
                </div>

                <div className={this.getClassesName()}>
                    <div className="multiple-files-modal-title">Add an attachment</div>
                    <div className="multiple-files-modal-file">
                        
                        <Input configs={{inputType: "file", label: "Attachment File *", id: this.state.fileId}} change={this.handleFileAdd}/>
                    </div>
                    <div className="multiple-files-modal-credits">
                        <Input configs={{inputType: "input", label: "Title  *"}} value={this.state.title} change={this.handleTitleChange}/>
                    </div>
                    <div className="multiple-files-modal-credits">
                        <Input configs={{inputType: "input", label: "Credits"}} value={this.state.credits} change={this.handleCreditsChange}/>
                    </div>
                    <div className="multiple-files-modal-language">
                        <Input configs={{inputType: "input", label: "Language"}} value={this.state.language} change={this.handleLanguageChange}/>
                    </div>
                    <div className="multiple-files-modal-button-container">
                        <button type="button"
                                className="multiple-files-modal-submit"
                                disabled={!this.canSubmitNewAttachment()}
                                onClick={this.handleAddFile}>
                            Submit
                        </button>
                        <button type="button"
                                className="multiple-files-modal-cancel"
                                onClick={this.handleCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default MultipleAttachments