export default class AjaxService {
    
    static post = (url, body, successCallback, errorCallback) => {
        return $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(body),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        }).then(successCallback, function (err){
            if(errorCallback){
                errorCallback(err)
                return;
            }

            window.location.href = "/login?returnUrl=" + encodeURIComponent(window.location.href) 
        });
    }

    static put = (url, body, successCallback, errorCallback) => {
        return $.ajax({
            type: "PUT",
            url: url,
            data: JSON.stringify(body),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        }).then(successCallback, function (err){
            if(errorCallback){
                errorCallback(err)
                return;
            }

            window.location.href = "/login?returnUrl=" + encodeURIComponent(window.location.href)
        });
    }

    static get = (url, successCallback, errorCallback) => {
        return $.ajax({
            type: "GET",
            url: url,
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        }).then(successCallback, function (err){
            if(errorCallback){
                errorCallback(err)
                return;
            }

            window.location.href = "/login?returnUrl=" + encodeURIComponent(window.location.href)
        });
    }

    static delete = (url, successCallback, errorCallback) => {
        return $.ajax({
            type: "DELETE",
            url: url,
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        }).then(successCallback, function (err){
            if(errorCallback){
                errorCallback(err)
                return;
            }

            window.location.href = "/login?returnUrl=" + encodeURIComponent(window.location.href)
        });
    }
}