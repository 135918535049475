import React, { Component } from 'react';
import StatusChangeButton from "./status-change";
import RequireInfo from "./require-info";
import ApprovalModal from "./approval-modal";
import AjaxService from "../services/AjaxService";


export default class Status extends React.Component{
    constructor(props) {
        super(props);
        
        this.state = {
            id: props.id,
            currentState: props.currentState,
            isLoading:false,
            isAdmin:props.isAdmin,
            nextStates: props.nextStates,
            proposalUsername: props.proposalUsername,
            proposalTitle: props.proposalTitle
        }
    }
    
    handleChangeStatusClick = (event, key) => {
        
        if(typeof event.preventDefault !== "undefined") {
            event.preventDefault();
        }
        
        var value = "";
        if(typeof event.value !== "undefined") {
            value = event.value;
        }
        else {
            value = event.target.value;
        }

        const endModel = {
            value: value,
            id: this.state.id,
            goto: key
        };

        this.setState({...this.state, isLoading: true}, () => {
            AjaxService.post("/umbraco/api/dashboardApi/ChangeStatus", endModel, (data) => {
                window.location.reload()
            }, (data) => {
                alert("The node you inserted was invalid")
                this.setState({...this.state, isLoading: false})
            })
        })
    }
    
    getOtherStatuses = () => {
        if(!this.state.isAdmin) return;
        if(this.state.isLoading) return;
        var self = this;
        return this.state.nextStates.map(nextStatus => {
            let modalContent = undefined;
            let showModal = false;
            
            let currentValueToLower = nextStatus.value.toLowerCase();
            if(currentValueToLower === "require additional info"){
                modalContent = function (callback, setModalState, key) { 
                    return <RequireInfo stateId={key} 
                                        callback={callback} 
                                        setModalOpenCallback={setModalState}
                                        username={self.state.proposalUsername}
                                        proposalTitle={self.state.proposalTitle}/>
                };
                showModal = true;
            }
            else if (currentValueToLower === "approve"){
                modalContent = function (callback, setModalState, key) { 
                    return <ApprovalModal stateId={key} callback={callback} setModalOpenCallback={setModalState} /> 
                };
                showModal = true;
            }
            
            
            return (
                <StatusChangeButton 
                    key={nextStatus.id}
                    stateId={nextStatus.id}
                    click={this.handleChangeStatusClick}
                    modalContent={modalContent}
                    showModal={showModal}
                    disabled={this.state.isLoading}
                    value={nextStatus.value} />
                
            )
        })
    }
    
    render() {
        return (
            <div className="best-practice-status-container">
                <div className="best-practice-status-label">
                    Status:
                </div>
                <div className="best-practice-status-inner-container">
                    {!this.state.isLoading ? this.state.currentState : <i className="fa fa-circle-o-notch fa-spin"></i>}
                </div>
                {this.getOtherStatuses()}
            </div>
        )
    }
}