export default class FileSizeCalculator{
    static calculate = (size) => {
        if(!size) return "0 B";
        
        size = parseInt(size)
        
        let possibleValues = ["B", "Kb", "Mb", "Gb"]
        var value = possibleValues[0];
        
        for(let i = 0; i <= possibleValues.length - 1; i++){
            value = possibleValues[i];

            if(size > 1024){
                //Non Byte
                size = size / 1024
            }
            else{
                break;
            }
        }

        size = Math.trunc(size);
        return size.toString() + " " + value
    }
}