import {useState} from "react";

const approvalModal = ({stateId, callback, setModalOpenCallback}) => {
    const [id, setId] = useState("")
    
    
    let handleSubmit = (event) => {
        event.preventDefault();
        
        callback({value: id}, stateId)
        
        setModalOpenCallback(false);
    }
    
    let handleCancel = (event) => {
        event.preventDefault();
        setModalOpenCallback(false);
    }
    
    return (
        <div className="modal-container">
            <div className="modal-title">
                Approval
            </div>
            <div className="modal-body">
                <form className="approval-form-container" onSubmit={handleSubmit}>
                    <div className="approval-id-input">
                        <label>Please insert here the ID of the Umbraco Node Preview</label>
                        <input type="number" 
                               value={id}
                               required
                               min="1"
                               onChange={(event) => setId(event.target.value)}/>
                    </div>
                    <div className="approval-form-buttons-container">
                        <div className="approval-form-button-submit">
                            <button type="submit">Approve</button>
                        </div>
                        <div className="approval-form-button-cancel">
                            <button type="button" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default approvalModal;