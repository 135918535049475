import {useState} from "react";

const statusChangeButton = ({stateId, showModal, click, modalContent, disabled, value}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    
    let handleSubmit = (event) => {
        if(showModal){
            setIsModalOpen(true)
        }
        else{
            click(event, stateId);    
        }
    }

    
    let modal = () => {
        if(!isModalOpen) return;
        
        return (
            <div className="input-modal">
                {modalContent(click, setIsModalOpen, stateId)}
            </div>
        )
    }
    
    return (
        <span>
            {modal()}
            <button
                onClick={handleSubmit}
                disabled={disabled}
                className="best-practice-status-inner-container best-practice-status-change">
                {value}
            </button>
        </span>
    )
}

export default statusChangeButton;