import React from "react";
import AjaxService from "../../services/AjaxService";
import Loading from "../components/loading";
import AlertService from "../../services/AlertService";

export default class SelfAssessmentPage extends React.Component {
  constructor(props) {
    super(props);

    // /survey?qId={questionnaireId}&vN={versionNumber}&saId={selfAssessmentId}
    // let params = {};
    // window.location.search.substring(1).split('&').forEach(_ => { const p=_.split("="); params[p[0]]=p[1]});

    this.state = { ...props, isSubmitted: true };
  }

  initializeSurvey = (config, data) => {
    Survey.StylesManager.applyTheme("modern");
    window.survey = new Survey.Model(config);
    // survey.checkErrorsMode = "onComplete";
    survey.checkErrorsMode = "onValueChanged";
    survey.showQuestionNumbers = "off";
    survey.clearInvisibleValues = "none";
    survey.isSinglePage = false;
    survey.onTextMarkdown.add(function (survey, options) {
      options.html = options.text;
    });

    if (data) {
      this.setState({
        ...this.state,
        assTitle: data.title,
        questionnaire: data.questionnaire,
      });

      survey.data = data.resultJSON;
      if (data.status == 1) {
        // if submitted
        survey.mode = "display";
      } else {
        this.setState({ ...this.state, isSubmitted: false });
      }
    } else {
      this.setState({ ...this.state, isSubmitted: false });
    }

    AjaxService.get(
      `/api/self-assessment/questionnaire-version/${this.state.questionnaireId}/${this.state.versionNumber}/evaluation/json`,
      (evaluation) => {
        evaluation = JSON.parse(evaluation);
        survey.onUpdateQuestionCssClasses.clear();
        survey.onUpdateQuestionCssClasses.add(
          (survey, { question, cssClasses }) => {
            //Aggiorna le classi delle domande leggendole dal evaluationJson, proprietà 'cssClass'
            let questionName = question.propertyHash.name().toString();
            let evalObj = evaluation[questionName];

            if (evalObj) {
              //Imposto le custom classes
              let classes = evalObj["cssClass"];
              if (classes) {
                cssClasses.mainRoot += ` ${classes}`;
              }

              //Imposto il principio e se è o no networking
              // CODICE COMMENTATO! IN CASO DI AGGIORNAMENTO MANUALE DEL JSON SI PUO' USARE
              // QUESTO CODICE CHE AUTOGENERA L'HTML DA COPIARE E INCOLLARE POI SUL JSON
              // A MANO
              // let principle = evalObj['principle'];
              // let netWeight = evalObj['netWeight'];
              //
              // if(netWeight){
              //     principle = `${principle} - NETWORKING`
              // }
              //
              // let template = document.createElement('div')
              // template.innerHTML = question.description.trim()
              //
              // let firstChild = template.firstChild
              // let span = document.createElement('span')
              // span.innerHTML = principle
              // span.className = 'badge badge-pill primary networking'
              // firstChild.appendChild(span)
              // let string = template.innerHTML.toString().replaceAll("\"", "\\\"");
              // console.log(questionName, string)
              // question.description = template.innerHTML
            }
          }
        );

        $("#surveyContainer").Survey({
          model: survey,
          onComplete: this.saveSurvey,
          onCurrentPageChanged: () => {
            window.scrollTo(0, 0);
            this.forceUpdate();
          },
        });

        this.setState({ ...this.state, isLoading: false });
      },
      (data) => {
        console.error("error", data);
        this.setState({ ...this.state, isLoading: false });
      }
    );
  };

  getSurveyConfig = () => {
    this.setState({ ...this.state, isLoading: true }, () => {
      var surveyDataFromLocalStorage = localStorage.getItem("surveyData");
      surveyDataFromLocalStorage = !!surveyDataFromLocalStorage
        ? JSON.parse(surveyDataFromLocalStorage)
        : undefined;

      AjaxService.get(
        `/api/self-assessment/questionnaire-version/${this.state.questionnaireId}/${this.state.versionNumber}/json`,
        (config) => {
          if (surveyDataFromLocalStorage) {
            this.initializeSurvey(config, surveyDataFromLocalStorage);
            localStorage.removeItem("surveyData");
            return;
          }

          if (this.state.selfAssessmentId) {
            this.getSurveyData((data) => {
              this.initializeSurvey(config, data);
            });
            return;
          }
          this.initializeSurvey(config);
        },
        (data) => {
          console.error("error", data);
          this.setState({ ...this.state, isLoading: false });
        }
      );
    });
  };

  getSurveyData = (success) => {
    this.setState({ ...this.state, isLoading: true }, () => {
      AjaxService.get(
        `/api/self-assessment/self-assessments/${this.state.selfAssessmentId}`,
        success,
        (data) => {
          console.error("error", data);
          this.setState({ ...this.state, isLoading: false });
        }
      );
    });
  };

  saveSurvey = (submit = true) => {
    const isNew = !this.state.selfAssessmentId;
    const postData = {
      questionnaireId: this.state.questionnaireId,
      versionNumber: this.state.versionNumber,
      resultJSON: survey.data,
      status: submit ? 1 : 0,
      title: this.state.assTitle,
    };
    if (!isNew) {
      postData.id = this.state.selfAssessmentId;
    }
    this.setState({ ...this.state, isSaving: true, isLoading: true }, () => {
      AjaxService[isNew ? "post" : "put"](
        "/api/self-assessment/self-assessments",
        postData,
        (data) => {
          if (submit) {
            window.location.href = `${this.state.surveysPageUrl}?qId=${this.state.questionnaireId}&saId=${data.id}`;
          }
          if (isNew) {
            const newurl = `${window.location.href}&saId=${data.id}`;
            window.history.pushState({ path: newurl }, "", newurl);
            this.setState({ ...this.state, selfAssessmentId: data.id });
          }
          this.setState(
            {
              ...this.state,
              isSaving: false,
              isLoading: false,
            },
            () => {
              AlertService.showAlert(
                "Your assessment has been successfully saved",
                "success"
              );
            }
          );
        },
        (data) => {
          if (data.status === 401) {
            localStorage.setItem("surveyData", JSON.stringify(postData));
            window.location.reload(); //Lasciamo gestire l'url ed il login a MVC
            return;
          }

          AlertService.showAlert(
            "An error occoured while saving your assessment",
            "danger"
          );

          this.setState({ ...this.state, isSaving: false, isLoading: false });
        }
      );
    });
  };

  getPageTabs = () => {
    if (window?.survey) {
      return survey.pages.map((p) => (
        <li className="nav-item" key={p.title}>
          <a
            onClick={() => this.selectPage(p)}
            className={`nav-link ${
              p.title == survey.currentPage.title ? "active" : ""
            }`}
          >
            {p.title}
          </a>
        </li>
      ));
    }
  };

  selectPage = (page) => {
    survey.currentPage = page;
  };

  callComplete = () => {
    if (survey.isCurrentPageHasErrors) {
      //if current page has errors completeLastPage calls validation
      survey.completeLastPage();
      return;
    }

    if (!survey.hasErrors()) {
      //if current page has not errors completeLastPage is bugged and completes even if other pages are blank, so I need to manually check for errors
      survey.completeLastPage();
      return;
    }
    //current page is clean but next pages incomplete => error messages
    AlertService.showAlert(
      "Your self assessment isn't complete. Answer all the questions before clicking the Complete button",
      "danger"
    );
  };

  downloadQuestionnaireInstructions = () => {
    window.open(
      `/api/self-assessment/questionnaire-version/${this.state.questionnaireId}/${this.state.versionNumber}/instructions`,
      "_blank"
    );
  };

  enableEdit = () => {
    survey.mode = "edit";
    this.setState({ ...this.state, isSubmitted: false });
  };

  componentDidMount() {
    this.setState({ ...this.state, canRender: true });
    this.getSurveyConfig();
  }

  getButton(text, isDisabled, isSaving, onClick, style = {}, tooltip) {
    return isDisabled ? (
      <button
        style={style}
        className="nav-link active"
        onClick={onClick}
        disabled="disabled"
        data-toggle="tooltip"
        data-placement="bottom"
        title={tooltip}
      >
        {text}
      </button>
    ) : (
      <button
        style={style}
        disabled={isSaving}
        className="nav-link active"
        onClick={onClick}
      >
        {text}
      </button>
    );
  }

  render() {
    if (!this.state.canRender) {
      return <></>;
    }

    return (
      <div className="survey-page">
        <div className="container">
          <h2>
            <strong>{this.state.questionnaire?.title}</strong>
          </h2>
          {this.state.isSubmitted && <h3>{this.state.assTitle}</h3>}
          {!this.state.isSubmitted && (
            <div className="survey-title-container">
              <span>Your assessment title:</span>
              <input
                className="survey-title"
                type="text"
                placeholder="Please insert an assessment title"
                value={this.state.assTitle}
                onChange={(e) =>
                  this.setState({ ...this.state, assTitle: e.target.value })
                }
              />
            </div>
          )}
          <div style={{ fontSize: "20px" }}>
            Please read the following instructions carefully before you start
            filling in the questionnaire: &nbsp;
            <a href="#" onClick={this.downloadQuestionnaireInstructions}>
              <i className="fa fa-file-pdf-o"></i>
            </a>
          </div>
          <div style={{ fontSize: "20px" }}>
            If you need any help you can contact us at{" "}
            <a href="mailto:helpdesk@incircle-kp.eu">helpdesk@incircle-kp.eu</a>
          </div>
        </div>
        <div className="sticky">
          <ul id="pages" className="nav nav-tabs">
            {this.getPageTabs()}
          </ul>

          <div className="nav-item">
            <button
              className="nav-link active"
              onClick={() => {
                window.location.href = `${this.state.surveysPageUrl}?qId=${this.state.questionnaireId}`;
              }}
            >
              Return to list
            </button>
            {!this.state.isSubmitted && (
              <>
                {this.getButton(
                  "Save Draft",
                  !this.state.assTitle,
                  this.state.isSaving,
                  () => this.saveSurvey(false),
                  { marginLeft: 12 },
                  "Assessment title is mandatory"
                )}
                {this.getButton(
                  "Complete",
                  !this.state.assTitle,
                  this.state.isSaving,
                  () => this.callComplete(),
                  { marginLeft: 12 },
                  "Assessment title is mandatory"
                )}
              </>
            )}
            {this.state.isSubmitted &&
              this.getButton(
                "Back to Draft state",
                false,
                this.state.isSaving,
                () => this.enableEdit(),
                { marginLeft: 12 }
              )}
          </div>
        </div>

        {this.state.isLoading && (
          <Loading loadingText="Your self assessment is loading, please wait..." />
        )}

        <div id="surveyContainer"></div>

        <div className="container"></div>
      </div>
    );
  }
}
