import React, { Component } from "react";
import AjaxService from "../../services/AjaxService";

export default function AssessmentCard({assessment, open}) {

    function navigate() {
        window.location.href = open;
    }

    return !assessment ? <></> : (
        <div className="assessment-card">
            <div className="panel panel-default">
                <div className="panel-body">
                    {/* version solo admin */}
                    <div className="row" style={{margin: 0}}>

                    <h5 className="col col-sm-9" style={{padding: 0}}>{assessment.title}</h5>
                    <span className="col col-sm-3" style={{padding: 0}}>Version: <strong>v{assessment.versionNumber}</strong></span>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: assessment.description}}></div>
                    <div className="buttons">
                    <button className="btn btn-success" onClick={navigate}>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>Proceed
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )
}