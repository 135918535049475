import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import "./submit-form/submit-form";
import "./status/status";

import ChangePasswordForm from "./change-password/change-password-form";

/*import RootComponent from './home.js';*/
import SubmitForm from "./submit-form/submit-form";
import Status from "./status/status";
import SurveysList from "./survey/pages/surveys-list";
import SelfAssessmentPage from "./survey/pages/self-assessment-page";
import ForgotPasswordForm from "./change-password/forgot-password-form";

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.Components = { SubmitForm, Status, SurveysList, SelfAssessmentPage, ChangePasswordForm, ForgotPasswordForm };



// const changePasswordForm = document.getElementById("change-password-form");
// if (typeof changePasswordForm !== "undefined") {
//     ReactDOM.render(<ChangePasswordForm />, changePasswordForm);
// }
// const selfAssessment = document.getElementById("selfAssessmentDivID");
// if (selfAssessment) {
//     ReactDOM.render(<SelfAssessmentPage />, selfAssessment);
// }
