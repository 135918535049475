import {useEffect} from "react";

const modal = ({modalTitle, modalBody}) => {
    
    useEffect(() => {
        /* All'apertura del modale impostiamo il body a hidden */
        $("body").css("overflow", "hidden")
        /* Al clean up reimpostiamo il modello precedente */
        return function cleanup(){
            $("body").css("overflow", "")
        }
    })
    
    var getModalTitle = () => {
        if(typeof modalTitle === "undefined") return;
        
        return (
            <div className="modal-title">
                {modalTitle()}
            </div>
        );
    }
    
    return (
        <div className="input-modal">
            <div className="modal-container">
    
                {getModalTitle()}
                <div className="modal-body">
                    {modalBody()}
                </div>
            </div>
        </div>
    )
}

export default modal