import Modal from "../modal";
import React from "react"

const preSubmitFormModal = ({isModalOpen, submitCallback, cancelCallback}) => {
    let handleCancel = () => {
        cancelCallback()
    }
    
    let getModal = () => {
        if(!isModalOpen) return (<div className="pre-submit-modal-container"></div>);
        
        return (
            <div className="pre-submit-modal-container">
                <Modal modalBody={modalBody} />
            </div>
        )
    }
    
    let modalText = () => {
        return (
            <div className="pre-submit-form-body-value">
                <p>
                    <h3>Submitting the best practice form you declare that</h3>    
                </p>
                <p>
                    You are authorised to submit the Best Practice Content
                </p>
                <p>
                    You are authorised to publish on InCircle website the Best Practice Content
                </p>
                <p>
                    You are NOT submitting any content that is unlawful, 
                    harmful, threatening, abusive, harassing, tortious, defamatory, vulgar,
                    obscene, libelous, invasive of another's privacy, hateful, or
                    racially, ethnically or otherwise objectionable.    
                </p>
                <p>
                    You are NOT submitting material that is copyrighted or protected.
                </p>
                <p>
                    You are the owner of intellectual property rights or other proprietary rights on the submitted contents
                    or you have permission from their 
                    rightful owner. In other words you are submitting <br />
                    -  content originally created by you or by your organization, or <br />
                    - content which is properly licensed to you by someone else, or <br />
                    - content which is available under Creative Commons CC BY-SA. <br />
                </p>
                <p>
                    When you submit content to which you hold 
                    the copyright, you agree to license it under Creative 
                    Commons Attribution-ShareAlike 3.0 Unported License (“CC BY-SA”),    
                </p>            
                <p>
                    You can submit content retrieved from other sources, but in such case
                    you warrant that the content is available under
                    terms that are compatible with the CC BY-SA 3.0 license and you must 
                    credit the author(s) in a reasonable fashion.    
                </p>
            </div>
        )
    }
    
    let modalBody = () => {
        return (
            <div className="pre-submit-body-container">
                <div className="pre-submit-body-inner-container">
                    {modalText()}
                </div>
                <div className="pre-submit-body-buttons">
                    <button type="button"
                            className="pre-submit-button"
                            onClick={submitCallback}>
                        Submit
                    </button>
                    <span>
                        Or
                    </span>
                    <button type="button"
                            className="pre-submit-cancel"
                            onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        )
    }
    
    return getModal()
}

export default preSubmitFormModal