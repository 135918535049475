import React from 'react' 

const keywordsInput = (props) => {
    const result = props.values;

    let buildKeywords = (index, value) => {
        result[index] = value;
        props.change(result.join(", "));
    }
    
    let getDisabledState = (index) => {
        if(result[index]){
            return false; //Se l'elemento è valorizzato lo lasciamo modificabile
        }
        if(index >= 3){
            for(let i = 0; i < index; i++){
                if(!result[i]) return true;
            }
        }
        return false;
    }
    
    return (
        <div>
            <div className="keywords-container">
                <input type="text" required value={result[0]} onChange={(event) => buildKeywords(0, event.target.value)} />
                <input type="text" required value={result[1]} onChange={(event) => buildKeywords(1, event.target.value)} />
                <input type="text" required value={result[2]} onChange={(event) => buildKeywords(2, event.target.value)} />
                <input type="text" value={result[3]} disabled={getDisabledState(3)} onChange={(event) => buildKeywords(3, event.target.value)} />
                <input type="text" value={result[4]} disabled={getDisabledState(4)} onChange={(event) => buildKeywords(4, event.target.value)} />
            </div>
        </div>
    )
}

export default keywordsInput