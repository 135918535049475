import React, { Component } from "react";
import AjaxService from "../../services/AjaxService";

export default function card({ assessment, onView, onDelete }) {
  function navigate() {
    let url = `/self-assessment-page?qId=${assessment.questionnaireId}&vN=${assessment.versionNumber}`;
    if (!!assessment.id) {
      url = `${url}&saId=${assessment.id}`;
    }

    window.location.href = url;
  }

  function deleteElement(value) {
    AjaxService.delete(
      `/api/self-assessment/self-assessments/${value}`,
      (e) => {},
      (error) => {
        console.log(error);
      }
    ).then((e) => {
      onDelete();
    });
  }

  let widthPercent = {
    width: assessment.completitionPercentage + "%",
  };

  return (
    <div className="survey-card">
      {assessment.status === 0 ? (
        <div className="panel draft panel-default">
          <div className="panel-percentage" style={widthPercent}></div>
          <div className="panel-body">
            <p className="flex space-between">
              <span className="left">
                <span className="text-percentage">
                  {assessment.completitionPercentage}% completed
                </span>
              </span>
              <span className="right">
                Version: <strong>v{assessment.versionNumber}</strong>
              </span>
            </p>
            <p className="flex space-between">
              <span className="right">
                Last saved:{" "}
                <strong>
                  {new Date(assessment.lastSave).toLocaleDateString()}
                </strong>
              </span>
            </p>
            <h5>{assessment.title}</h5>
            {assessment.userData && (
              <>
                <p className="flex flex-col">
                  <span>
                    by{" "}
                    <strong>
                      {assessment.userData.firstName}{" "}
                      {assessment.userData.lastName}
                    </strong>{" "}
                    ({assessment.userData.email})
                  </span>
                  <strong>
                    {assessment.userData.organization.toUpperCase()}
                  </strong>
                </p>
              </>
            )}
            <div className="buttons">
              <button className="btn btn-primary" onClick={navigate}>
                <i className="fa fa-arrow-right" aria-hidden="true"></i>Open and
                finish
              </button>
              <button
                className="btn btn-default"
                onClick={() => deleteElement(assessment.id)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="panel completed panel-default">
          <div className="panel-percentage"></div>
          <div className="panel-body">
            <p className="flex space-between">
              <span className="left">
                Completed on:{" "}
                <strong>
                  {new Date(assessment.lastSave).toLocaleDateString()}
                </strong>
              </span>
              <span className="right">
                Version: <strong>v{assessment.versionNumber}</strong>
              </span>
            </p>
            <h5>{assessment.title}</h5>
            {assessment.userData && (
              <>
                <p className="flex flex-col">
                  <span>
                    by{" "}
                    <strong>
                      {assessment.userData.firstName}{" "}
                      {assessment.userData.lastName}
                    </strong>{" "}
                    ({assessment.userData.email})
                  </span>
                  <strong>
                    {assessment.userData.organization.toUpperCase()}
                  </strong>
                </p>
              </>
            )}
            <div className="buttons">
              <button className="btn btn-success" onClick={onView}>
                <i className="fa fa-search" aria-hidden="true"></i>View report
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
