import React from 'react'
import KeywordsInput from "./keywords";
import MultipleAttachments from "./multiple-attachments";

const input = ({configs, value, change, addAttachment, removeAttachment,attachments }) => {
    let inputElement = null;

    switch (configs.inputType) {
        case ('input'):
            inputElement = <input type="text" value={value} onChange={change}/>
            break;
        case ('select'):
            inputElement = (
                <select onChange={change} value={value}>
                    {configs.options.map(opt => (
                        <option key={opt.value} 
                                value={opt.value}
                                disabled={opt.disabled}
                        >{opt.displayValue}</option>
                    ))}
                </select>
            )
            break;
        case('file'):
            inputElement = (
                <input type="file" onChange={change} key={configs.id} accept={configs.accept} />
            )
            break;
        case('keywords'):
            inputElement = (
                <KeywordsInput change={(value) => change(event, value)} values={configs.values} />
            )
            break;
        case('attachments'):
            inputElement = (
                <MultipleAttachments addAttachment={addAttachment} 
                                     attachments={attachments} 
                                     removeAttachment={removeAttachment}/>
            )
            break
    }
    
    let getLabel = () => {
        if(configs.label){
            return <label className="form-submit-input-label">{configs.label}</label>
        }
        return undefined;
    }

    return (
        <span className="form-submit-input-container">
            {getLabel()}
            {inputElement}
        </span>
    )
}
export default input;