import React, { Component } from "react";

import AjaxService from "../../services/AjaxService";

export default class AvailableAssessments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assessments: [],
      isLoading: false,
      ...props
    };
  }

  getAssessmentsList = () => {
    this.setState({ ...this.state, isLoading: true }, () => {
      AjaxService.get(
        "/api/self-assessment/questionnaires",
        (data) => {
          this.setState({ ...this.state, assessments: data });
        },
        (data) => {
          this.setState({ ...this.state, isLoading: false });
        }
      );
    });
  };

  navigateToQuestionnaireList = (questionnaireId) => {
    return `${this.state.surveysPageUrl}?qId=${questionnaireId}`;
  };

  componentDidMount() {
    this.getAssessmentsList();
  }

  render() {
    return (
      <div className="content-cards">
        <h6>Circular</h6>
        <div className="assessments">
          {this.state.assessments ? (
            this.state.assessments.map((a) => {
              return (
                <a
                  href={this.navigateToQuestionnaireList(a.questionnaireId)}
                  key={a.questionnaireId}
                  className={
                    a.questionnaireId == this.state?.questionnaireId
                      ? "active"
                      : ""
                  }
                >
                  {a.title}
                </a>
              );
            })
          ) : (
            <p> No questionnaires available. </p>
          )}
        </div>
      </div>
    );
  }
}
