import React, { Component } from "react";
import Card from "./card";
import AjaxService from "../../services/AjaxService";
import Loading from "./loading";
import SelfAssessmentList from "./self-assessment-list";

export default class SelfAssessments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selfAssessments: [],
      assessment: null,
      isLoading: false,
      ...props,
    };
  }

  getSelfAssessmentsList = () => {
    this.setState({isLoadingSelfAssessments: true})
    this.setState({ ...this.state, isLoading: true }, () => {
      AjaxService.get(
        `/api/self-assessment/self-assessments?qId=${this.state.questionnaireId}`,
        (data) => {
          this.setState({
            ...this.state,
            selfAssessments: data,
            isLoadingSelfAssessments: false
          });
        },
        (data) => {
          this.setState({ ...this.state, isLoading: false });
        }
      );
    });
  };

  getAssessmentInfo = () => {
    this.setState({ ...this.state, isLoading: true }, () => {
      AjaxService.get(
        `/api/self-assessment/questionnaires/${this.state.questionnaireId}`,
        (data) => {
          this.setState({
            ...this.state,
            assessment: data,
            isLoading: false,
          });
        },
        (data) => {
          this.setState({ ...this.state, isLoading: false });
        }
      );
    });
  };

  componentDidMount() {
    if (this.state.questionnaireId) {
      this.getSelfAssessmentsList();
      this.getAssessmentInfo();
    }
  }

  downloadQuestionnaireInstructions = () => {
    window.open(
        `/api/self-assessment/questionnaire-version/${this.state.questionnaireId}/${this.state.assessment.lastVersionNumber}/instructions`,
        "_blank"
    );
  }
  navigateToNewQuestionnaire = () => {
    window.location.href = `/self-assessment-page?qId=${this.state.questionnaireId}&vN=${this.state.assessment.lastVersionNumber}`;
  };
  navigateToCSVDownload = () => {
    window.open(
      `/api/self-assessment/questionnaire-version/${this.state.questionnaireId}/${this.state.assessment.lastVersionNumber}/generate-csv-recap`,
      "_blank"
    );
  };

  render() {
    return (
      <div className="content-cards">
        { this.state.assessment ? (
          <>
            <h3 className="content-cards-title">
              {this.state.assessment.title}
            </h3>
            <div dangerouslySetInnerHTML={{__html: this.state.assessment.description}}></div>
            
            
            
            <div className="buttons">
              {this.state.assessment.lastVersionNumber && (
                  <button
                      className="btn btn-primary version"
                      onClick={() => this.navigateToNewQuestionnaire()}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> New
                    Assessment{" "}
                    <span className="badge">
                    v{this.state.assessment.lastVersionNumber}
                  </span>
                  </button>
              )}
              {this.state.isAdmin && (
                  <button
                      className="btn btn-primary version"
                      onClick={() => this.navigateToCSVDownload()}
                  >
                    <i className="fa fa-download" aria-hidden="true"></i> Download
                    CSV recap{" "}
                    <span className="badge">
                    v{this.state.assessment.lastVersionNumber}
                  </span>
                  </button>
              )}
              { this.state.assessment.hasHowToImproveFile &&
              (
                  <button className="btn btn-success" onClick={this.downloadQuestionnaireInstructions}>
                    <i className="fa fa-download" aria-hidden="true"></i>
                    Download Instructions
                  </button>
              )
              }
            </div>
          </>    
        ) : <></> }

        {this.state.isLoadingSelfAssessments ?
            <Loading /> : this.state.assessment ? (
                <>
                  <SelfAssessmentList
                      selfAssessments={this.state.selfAssessments?.filter(e => e.status === 0)}
                      onDelete={this.getSelfAssessmentsList}
                      statusText='Drafts' />

                  <SelfAssessmentList
                      selfAssessments={this.state.selfAssessments?.filter(e => e.status === 1)}
                      statusText='Completed'
                      onView={(assessment) => this.state.selfAssClicked(assessment)}/>
                </>
            )  : <div className="alert alert-info" role="alert">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              There aren't any assessments here...</div> }
      </div>)
  }
}
