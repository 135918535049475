import Input from "./input";
import React from "react";
import PreSubmitFormModal from "./pre-submit-form-modal";
import IdService from "../services/IdService";

export default class SubmitForm extends React.Component {

    initialState = undefined;
    constructor(props) {
        super(props);

        this.state = {
            isFormVisible: false,
            isLoading: false,
            isSubmitting: false,
            isReloading: false,
            isPreSubmitForOpen: false,
            fields: {
                title: {
                    configs: {
                        inputType: 'input',
                        label: 'Title *',
                    },
                    value: ""
                },
                type: {
                    configs: {
                        inputType: 'select',
                        options: [
                            {value: "", displayValue: "Type *", disabled: true},
                            ...props.types
                        ]
                    },
                    value: ""
                },
                category: {
                    configs: {
                        inputType: 'select',
                        options: [
                            {value: "", displayValue: "Category *", disabled: true},
                            ...props.categories,
                        ]
                    },
                    value: ""
                },
                country: {
                    configs: {
                        inputType: 'select',
                        label: 'Country *',
                        options: [
                            {value: "", displayValue: "Country", disabled: true},
                            ...props.countries,
                        ]
                    },
                    value: ""
                },
                proposalFile: {
                    configs: {
                        id: 'random',
                        inputType: 'file',
                        label: 'Upload your best practice *',
                        accept: 'application/pdf'
                    },
                    value: null
                },
                keywords: {
                    configs: {
                        inputType: 'keywords',
                        label: 'key words (min 3 maximum 5) *',
                        values: ["", "", "", "", ""]
                    }
                },
                attachments: {
                    configs: {
                        inputType: 'attachments',
                        label: 'Attachments (documents, report, images…)'
                    },
                    values: []
                }
            },
            error: ''
        }
        this.initialState = {...this.state}
    }

    goToTop = () => {
        $('html, body').animate({
            scrollTop: $("#submit-form-inner").offset().top
        }, 10);
    }
    
    handleFormVisible = (show) => {
        var currentFormVisibility = this.state.isFormVisible;
        this.setState({isFormVisible: show})
        if (!this.show && currentFormVisibility) {
            const fields = {...this.initialState.fields}
            const updatedForm = {...fields.proposalFile}

            updatedForm.configs.id = IdService.makeId(5);

            fields.proposalFile = updatedForm;
            fields.keywords.configs.values = ["", "", "", "", ""] 

            this.setState({...this.initialState, fields: fields})
        }
    }

    getFormClasses = () => {
        let classes = "submit-form-inner-container ";
        classes += this.state.isFormVisible ? "visible" : "not-visible";

        return classes.trim();
    }

    inputChangeHandler = (event, inputIdentifier) => {
        const form = {...this.state.fields}
        const updatedForm = {...form[inputIdentifier]}

        updatedForm.value = event.target.value;

        form[inputIdentifier] = updatedForm;

        this.setState({...this.state, fields: form})
    }

    inputValueHandler = (event, inputIdentifier, value) => {

        const form = {...this.state.fields}
        const updatedForm = {...form[inputIdentifier]}

        updatedForm.value = value;

        form[inputIdentifier] = updatedForm;

        this.setState({...this.state, fields: form})
    }

    handleAddAttachment = (result) => {
        const form = {...this.state.fields}
        const updatedForm = {...form.attachments}

        if (!updatedForm.values)
            updatedForm.values = []

        updatedForm.values.push(result);
        
        updatedForm.value = updatedForm.values

        form.attachments = updatedForm;
        this.setState({...this.state, fields: form})
    }

    handleRemoveAttachment = (index) => {
        const form = {...this.state.fields}
        const updatedForm = {...form.attachments}

        if (updatedForm.values) {
            updatedForm.values = updatedForm.values.splice(index, 1)
        }
        
        updatedForm.value = updatedForm.values

        form.attachments = updatedForm;
        this.setState({...this.state, fields: form})
    }

    fileChangeHandler = (event, inputIdentifier) => {
        const form = {...this.state.fields}
        const updatedForm = {...form[inputIdentifier]}

        const files = event.target.files;

        if (files) {
            updatedForm.value = [];
            for (let file in files) {
                const uploadedFile = files[file];
                if (uploadedFile.name && uploadedFile.name !== "item") {
                    updatedForm.value.push({
                        name: uploadedFile.name,
                        type: uploadedFile.type,
                        size: uploadedFile.size,
                        file: uploadedFile
                    })
                }
            }
            form[inputIdentifier] = updatedForm;
            this.setState({fields: form})
        }
    }
    
    canSubmit = () => {
        return this.state.fields.keywords
            && this.state.fields.keywords.configs.values.filter(a => a).length >= 3
            && this.state.fields.keywords.configs.values.filter(a => a).length <= 5
            && this.state.fields.proposalFile.value
            && this.state.fields.proposalFile.value[0]
            && this.state.fields.proposalFile.value[0].file
            && this.state.fields.title.value
            && this.state.fields.type.value
            && this.state.fields.category.value
            && this.state.fields.country.value
            && !this.state.isLoading;
    }
    
    handleOnSubmit = (event) => {
        event.preventDefault();
        this.setState({isPreSubmitForOpen: true})
    }
    onSubmit = (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true,
            isSubmitting: true,
            isFormVisible: false,
            isPreSubmitForOpen: false,
        }, () => {
            this.goToTop();
            const formData = new FormData();
            formData.append("title", this.state.fields.title.value)
            formData.append("type", this.state.fields.type.value)
            formData.append("category", this.state.fields.category.value)
            formData.append("country", this.state.fields.country.value)
            formData.append("keywords", this.state.fields.keywords.value)

            if(this.state.fields.proposalFile && this.state.fields.proposalFile.value) {
                var file = this.state.fields.proposalFile.value[0];
                if (file) {
                    formData.append("proposalForm", file.file, "PROPOSALFILE_" + file.name)
                }
            }


            if(this.state.fields.attachments.value){
                for (let i = 0; i < this.state.fields.attachments.value.length; i++){
                    var value = this.state.fields.attachments.value[i];
                    formData.append("attachment_" + i.toString(),
                        value.file,
                        value.file.name)
                    formData.append("attachment_" + i.toString() + "_spec",
                        JSON.stringify({
                            title: value.title,
                            credits: value.credits,
                            language: value.language
                        }))
                }
            }

            var self = this;
            $.ajax({
                url : '/umbraco/api/dashboardapi/createProposal',
                data : formData,
                processData : false,
                contentType: false,
                type: 'POST'
            }).done(function(data){
                self.setState({
                    ...self.state,
                    isLoading: false,
                    isSubmitting: false,
                    isReloading: true,
                })
                console.log(data)
                window.location.href = data.redirectUrl;
            }).fail(function(data){
                if(data.status === 401){ //Unauthorized
                    window.location.reload()
                    return;
                }
                
                console.error(data);
                
                var error = "";
                if(data.responseJSON){
                    error = data.responseJSON.Message
                }
                
                if(!error || error.toLowerCase().trim() === "error"){
                    error = "An error has occured and your proposal cannot be saved"
                }
                
                self.setState({
                    ...self.state,
                    isLoading: false,
                    isFormVisible: true,
                    isSubmitting: false,
                    isReloading: false,
                    error: error
                })
            });
        })
    }

    getBestPracticeButton = () => {
        if (this.state.isFormVisible || this.state.isSubmitting || this.state.isReloading) return undefined;
        return (
            <div className="submit-form-submit-best-practice-container">
                <div className="submit-best-practice-container">
                    <button className="submit-best-practice-button" onClick={() => this.handleFormVisible(true)}>
                        Submit your best practice
                    </button>
                </div>
            </div>
        )
    }
    
    getSubmitContent = () => {
        if(this.state.isLoading) return (<i className='fa fa-spin fa-circle-o-notch'></i>)
        return ("Submit")
    }

    getForm = () => {
        var submittingStatus = () => {
            if(this.state.isSubmitting){
                return (
                    <div className="submit-form-alert-response">
                        <div className="submit-form-alert-icon">
                            <i className="fa fa-spin fa-circle-o-notch"></i>
                        </div>
                        <div className="submit-form-alert-title">
                            Saving your proposal. Please stand by...
                        </div>
                    </div>
                )
            }
            else if(this.state.isReloading){
                return (
                    <div className="submit-form-alert-response success">
                        <div className="submit-form-alert-icon">
                            <i className="fa fa-check-circle"></i>
                        </div>
                        <div className="submit-form-alert-title">
                            Your proposal has been saved successfully! Refreshing the page..
                        </div>
                    </div>
                )
            }
        }
        

        var alert = () => {
            if(this.state.error){
                return (
                    <div className="form-error-container">
                        <div className="alert alert-danger">
                            {this.state.error}
                        </div>
                    </div>)
            }
            
            return undefined;
        }

        return (
            <div>
                {submittingStatus()}
                <div className={this.getFormClasses()}>
                    {alert()}
                    <form onSubmit={this.handleOnSubmit}>
                        <PreSubmitFormModal submitCallback={this.onSubmit}
                                            isModalOpen={this.state.isPreSubmitForOpen}
                                            cancelCallback={() => this.setState({...this.state, isPreSubmitForOpen: false})} />
                        <div className="submit-form-react-container">
                            <div className="submit-form-react-inner-container">
                                <div className="submit-form-inner-title-container">
                                    <Input configs={this.state.fields.title.configs}
                                           value={this.state.fields.title.value}
                                           change={(event) => this.inputChangeHandler(event, "title")}/>
                                </div>
                                <div className="submit-form-inner-spec-container">
                                    <div
                                        className="submit-form-inner-double-container submit-form-inner-type-container">
                                        <Input configs={this.state.fields.type.configs}
                                               value={this.state.fields.type.value}
                                               change={(event) => this.inputChangeHandler(event, "type")}/>
                                    </div>
                                    <div
                                        className="submit-form-inner-double-container submit-form-inner-spec-container">
                                        <Input configs={this.state.fields.category.configs}
                                               value={this.state.fields.category.value}
                                               change={(event) => this.inputChangeHandler(event, "category")}/>
                                    </div>
                                </div>
                                <div className="submit-form-inner-field-container">
                                    <Input configs={this.state.fields.country.configs}
                                           value={this.state.fields.country.value}
                                           change={(event) => this.inputChangeHandler(event, "country")}/>
                                </div>
                                <div className="submit-form-inner-field-container">
                                    <Input configs={this.state.fields.proposalFile.configs}
                                           value={this.state.fields.proposalFile.value}
                                           change={(event) => this.fileChangeHandler(event, "proposalFile")}/>
                                </div>
                                <div className="submit-form-inner-field-container">
                                    <Input configs={this.state.fields.keywords.configs}
                                           change={(event, value) => this.inputValueHandler(event, "keywords", value)}/>
                                </div>
                                <div className="submit-form-inner-field-container">
                                    <Input configs={this.state.fields.attachments.configs}
                                           attachments={this.state.fields.attachments.values}
                                           addAttachment={this.handleAddAttachment}
                                           removeAttachment={this.handleRemoveAttachment}/>
                                </div>
                            </div>
                            <div className="submit-form-submit-best-practice-container">
                                <div className="submit-best-practice-container">
                                    <button className="submit-best-practice-button" type="submit" disabled={!this.canSubmit()}>
                                        {this.getSubmitContent()}
                                    </button>
                                    <span>
                                            Or
                                        </span>
                                    <a href="#" className="fix-style" onClick={() => this.handleFormVisible(false)}>
                                        Cancel
                                    </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {

        return (
            <div>
                {this.getForm()}
                
                {this.getBestPracticeButton()}
                
            </div>

        )
    }
}