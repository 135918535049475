import React, {useEffect, useState} from "react";
import usePasswordValidator from "./usePasswordValidator";
import ChangePasswordValidator from "./change-password-validator";
import AjaxService from "../services/AjaxService";
import AlertService from "../services/AlertService";

export default function ForgotPasswordForm(props) {
    const [form, setForm] = useState({
        token: props.token
    })
    const [message, setMessage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const {isValid} = usePasswordValidator(form.password, form.confirmPassword)

    let handleInputChange = (event) => {
        const name = event.target.name;
        setForm({...form, [name]: event.target.value})
    }
    
    let submit = (event) => {
        event.preventDefault()
        setMessage(undefined);
        setIsLoading(true)

        AjaxService.post(`/umbraco/api/AccountApi/ForgotPassword`, form,
            (data) => {
                AlertService.showAlert('Your password has been changed successfully', 'success')
                setIsLoading(false)
                setMessage({message: 'Your password has been changed successfully', type: 'success'})
                
                if(data.redirectUrl){
                    //Per far vedere i messaggi
                    setTimeout(() => {
                        window.location.href = data.redirectUrl
                    }, 1000)
                }
            }, 
            (error) => {
                AlertService.showAlert(error.responseJSON?.ExceptionMessage, 'danger')
                setMessage({message: error.responseJSON?.ExceptionMessage, type: 'danger'})
                setIsLoading(false)
            })
    }
    
    return (
        <>
            <div className="incircle-form-title">
                <h3>Forgot Password</h3>
                <div className="registration-password-criteria">
                    your password should:
                    <ChangePasswordValidator password={form.password}
                                             confirmPassword={form.confirmPassword}/>
                </div>
            </div>

            <form onSubmit={submit}>
                <div className="incircle-form-input-container">
                    <input type='password' placeholder='Password'
                           value={form.password} name='password' onChange={handleInputChange}/>
                </div>
                <div className="incircle-form-input-container">
                    <input type='password' placeholder='Confirm Password'
                           value={form.confirmPassword} name='confirmPassword'
                           onChange={handleInputChange}/>
                </div>
                <div className="incircle-form-button-submit-container">
                    <button disabled={isLoading || !isValid} type="submit" className="incircle-form-button-submit">
                        {!isLoading ? 'Change' : <i className='fa fa-circle-o-notch fa-spin'></i>}
                    </button>
                </div>
                {(message?.message) && (
                    <div className={`login-form-response ${message.type}`}>
                        {message.message}
                    </div>
                )}
            </form>
        </>
    )
}