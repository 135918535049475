import React from "react";
import AjaxService from "../../services/AjaxService";
import AssessmentCard from "./assessment-card";
import Loading from "./loading";


export default class AssessmentList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            assessments: [],
        };
    }


    getAssessmentsList = () => {
        this.setState({...this.state, isLoading: true}, () => {
            console.log(this.state)
            AjaxService.get(
                "/api/self-assessment/questionnaires",
                (data) => {
                    this.setState({...this.state, isLoading: false, assessments: data});
                },
                (data) => {
                    this.setState({...this.state, isLoading: false});
                }
            );
        });
    };

    navigateToQuestionnaireList = (questionnaireId) => {
        return `/surveys?qId=${questionnaireId}`;
    };

    componentDidMount() {
        this.getAssessmentsList();
    }

    render() {

        let assessments = this.state.assessments.map((a) => 
            <AssessmentCard assessment={a}
                open={this.navigateToQuestionnaireList(a.questionnaireId)}
                key={a.questionnaireId}/>);

        return (
            <div className="content-cards">
                
                <h3 className="content-cards-title">Circular tourism assessments</h3>
                
                {this.state.isLoading && <Loading/>}
                {(this.state.assessments && !this.state.isLoading) && assessments}
            </div>
        )
    }

}