import React, {useEffect, useState} from "react";
import AjaxService from "../services/AjaxService";
import AlertService from "../services/AlertService";
import ChangePasswordValidator from "./change-password-validator";
import usePasswordValidator from "./usePasswordValidator";

export default function ChangePasswordForm(props) {

    const [form, setForm] = useState({})
    const [message, setMessage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const {isValid} = usePasswordValidator(form.password, form.confirmPassword)
    
    let handleInputChange = (event) => {
        const name = event.target.name;
        setForm({...form, [name]: event.target.value})
    }

    let submit = (event) => {
        event.preventDefault()
        setMessage(undefined)
        setIsLoading(true)
        
        AjaxService.post(`/umbraco/api/AccountApi/ChangePassword`, form,
            (data) => {
                AlertService.showAlert('Your password has been changed successfully', 'success')
                setIsLoading(false)
                setMessage({message: 'Your password has been changed successfully', type: 'success'})
            }, (error) => {
                AlertService.showAlert(error.responseJSON?.ExceptionMessage, 'danger')
                setMessage({message: error.responseJSON?.ExceptionMessage, type: 'danger'})
                setIsLoading(false)
            })
    }

    return (
        <>
            <div className="incircle-form-title">
                <h3>Change Password</h3>
                <div className="registration-password-criteria">
                    your password should:
                    <ChangePasswordValidator password={form.password}
                                             confirmPassword={form.confirmPassword}/>
                </div>
            </div>

            <form onSubmit={submit}>
                <div className="incircle-form-input-container">
                    <input type='password' placeholder='Current Password'
                           value={form.currentPassword} name='currentPassword' onChange={handleInputChange}/>
                </div>
                <div className="incircle-form-input-container">
                    <input type='password' placeholder='Password'
                           value={form.password} name='password' onChange={handleInputChange}/>
                </div>
                <div className="incircle-form-input-container">
                    <input type='password' placeholder='Confirm new Password'
                           value={form.confirmPassword} name='confirmPassword'
                           onChange={handleInputChange}/>
                </div>
                <div className="incircle-form-button-submit-container">
                    <button disabled={isLoading || !isValid || !form.currentPassword} type="submit" className="incircle-form-button-submit">
                        {!isLoading ? 'Change' : <i className='fa fa-circle-o-notch fa-spin'></i>}
                    </button>
                </div>
                {(message?.message) && (
                    <div className={`login-form-response ${message.type}`}>
                        {message.message}
                    </div>
                )}
            </form>
        </>
    )
}