import React, {Component, useEffect, useState} from "react";

export default function Alert({text, type, timeoutInMs, isVisible}) {
    const [isAlertVisible, setIsVisible] = useState(isVisible)

    let timeout;
    useEffect(() => {

        if(timeout){
            clearTimeout(timeout)
        }

        if(!isVisible){
            setIsVisible(false)
            return;
        }

        timeout = setTimeout(() => {
            setIsVisible(false)
        }, timeoutInMs)
    }, [text, type, timeoutInMs, isVisible])

    let style = {
        marginBottom: '15px',
        boxShadow: '0px 3px 10px #000000'
    }
    
    let getClassNames = () => {
        return `alert alert-${type} area-riservata-alert`
    }
    
    return !isAlertVisible ? <></> : (
        <div className={getClassNames()} role="alert" style={style}>
            {text}
        </div>
    )
}