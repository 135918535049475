import {useState} from "react";

const requireInfo = ({stateId, username, proposalTitle, callback, setModalOpenCallback}) => {
    const [requiredInfo, setRequiredInfo] = useState("")
    
    
    let handleSubmit = (event) => {
        event.preventDefault();
        
        callback({value: requiredInfo}, stateId)
        
        setModalOpenCallback(false);
    }
    
    let handleCancel = (event) => {
        event.preventDefault();
        setModalOpenCallback(false);
    }
    
    return (
        <div className="modal-container">
            <div className="modal-title">
                Require More Information
            </div>
            <div className="modal-body">
                <form className="required-form-container" onSubmit={handleSubmit}>
                    <div className="required-form-header">
                        Good day {username},    
                    </div>
                    
                    <p>More information on <b>{proposalTitle}</b> are required: </p>
                    <p>
                        <textarea required value={requiredInfo} 
                                  onChange={(event) => setRequiredInfo(event.target.value)}>
                        </textarea>
                    </p>
                    
                    <div  className="required-form-footer">
                        Best wishes,<br />
                        InCircle team <br />
                        incircle@areasciencepark.it
                    </div>
                    <div className="require-info-modal-button-container">
                        <div className="require-info-modal-submit">
                            <button type="submit">Require</button>
                        </div>
                        <div className="require-info-modal-cancel">
                            <button type="button" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default requireInfo;