import React from "react";
import Card from "./card";

export default function SelfAssessmentList({selfAssessments, statusText, onView, onDelete}) {
    if (!selfAssessments) {
        return (
            <>
                <h4 className="content-cards-title">{statusText}</h4>
                <div className="alert alert-info" role="alert">
                    <i className="fa fa-info-circle" aria-hidden="true"></i>There
                    aren't any {statusText.toLowerCase()} questionnaires here.
                    <br/>
                    Click on the <strong>New Assessment</strong> button to start
                    one!
                </div>
            </>

        );
    }

    return (
        <>
            <h4 className="content-cards-title">{statusText}</h4>
            {selfAssessments.map((a) => <Card
                    key={a.id}
                    onDelete={() => {
                        if(onDelete) {
                            onDelete()
                        }
                    }}
                    onView={() => onView(a)}
                    assessment={a}
                />
            )}
        </>
       
    )
}