import React from "react";
import usePasswordValidator from "./usePasswordValidator";
export default function ChangePasswordValidator({password, confirmPassword}) {
    const {atLeast7CharLong, uppercase, lowercase, numbers, match} = usePasswordValidator(password, confirmPassword)
    
    let getIconForCondition = (isValid) => {
        
        let style = {
            color: isValid ? 'green' : 'red'
        }
        return isValid ? 
            <i className='fa fa-check-circle' style={style}></i> : 
            <i className='fa fa-times-circle' style={style}></i> 
    }
    
    return (
        <ul>
            <li>
                {getIconForCondition(atLeast7CharLong)}
                be longer than 7 characters,
            </li>
            <li>
                contain {getIconForCondition(uppercase)} upper 
                and {getIconForCondition(lowercase)} lower case,
            </li>
            <li>
                {getIconForCondition(numbers)}
                contain at least one number
            </li>
            <li>
                {getIconForCondition(match)}
                passwords should match
            </li>
        </ul>
    )
}